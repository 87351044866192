import React, { Fragment, useState, useRef } from "react";
import Ingrediente from "../Ingredientes/Ingrediente";
import BtnEliminar from "../BtnEliminar/BtnEliminar";
import Swal from "sweetalert2";
import '../Menu/Menu.css';
import ModalEditarProducto from "../ModalEditarProducto/ModalEditarProducto";
import BtnEditar from "../BtnEditar/BtnEditar";

const estilosPlato = {
    borderRadius: "5%",
    border: "1px solid gray",
    alignContent: "center",
    flexDirection: "column"
};

const estilosImg = {
    borderRadius: "15%"
}

const estilosColumna = {
    padding: "0px",
    margin: "0px",
};

const contenedorIngredientes = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: "center",
};
const contenedorImagenes = {
    display: "flex",
    flexDirection: "row",
    gap: "13px",
}
const botonesSeccion = {
    alignSelf: "flex-end",
    marginRight: "15px",
}
const Plato = ({ producto, remove, btnEliminar, btnEliminarIngrediente, deleteIngrediente, monedaActual, isModal, id, isFirstRowElement, idPagina }) => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const platoRef = useRef(null);

    const alertDelete = () => {
        Swal.fire({
            title: '¿Deseas eliminar este producto?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: '&iexcl;Si, eliminar!',
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'primary-button',
                cancelButton: 'primary-line-button'
            }
        }).then((result) => {
            if (result.value) {
                remove(producto.idProducto);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Producto eliminado',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    return (
        <div id={id} className="row justify-content-start m-1 pt-1 " style={estilosPlato} ref={platoRef}>
            {btnEliminar !== true ? <Fragment>
                <div style={botonesSeccion}>
                    <BtnEditar click={() => setIsEditModalOpen(true)} />
                    <BtnEliminar click={alertDelete} titleTooltip="plato" customClass={"botonEliminar"} />
                </div>
                <ModalEditarProducto data={producto} btnEliminar={true} monedaActual={monedaActual} isEditModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} />
            </Fragment> : null}
            <div style={contenedorImagenes} className="ml-4">
                <div className="col-5 text-center mt-1" style={estilosColumna}>
                    <img
                        id={`${producto.idProducto}${isModal ? "modal" : ""}`}
                        src={producto.srcPic !== "" ? producto.srcPic : "https://placehold.co/156x156?text=Pictograma\\n156x156"}
                        alt="Añada su plato"
                        width="120"
                        height="120"
                        style={estilosImg}
                    />
                </div>
                <div className="col-5 text-center mt-1 mr-3" style={estilosColumna}>
                    <img
                        id={`${producto.idProducto}${isModal ? "modal" : ""}`}
                        src={producto.srcImg !== "" ? producto.srcImg : "https://placehold.co/156x156?text=Foto\\n156x156"}
                        alt="Añada su plato"
                        width="120"
                        height="120"
                        style={estilosImg}
                    />
                </div>
            </div>
            <div>
                <div className="col text-center py-4" >
                    <h6 className="text-dark text-center font-weight-bold text-uppercase">{producto.nombre} {producto.precio !== "" ? monedaActual + producto.precio : null}</h6>
                </div>
            </div>
            <div style={contenedorIngredientes}>
                {producto.ingredientes.map((ingrediente) => {
                    return (
                        <Ingrediente
                            data={ingrediente}
                            key={producto.idProducto + "-" + ingrediente.idIngrediente}
                            deleteIngrediente={deleteIngrediente}
                            btnEliminarIngrediente={btnEliminarIngrediente}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Plato;
