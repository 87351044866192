import React from 'react';

const Instrucciones = () => {


    return (
        <div className="row justify-content-center">
            <div className="col-4">
                <h3>Paso 1</h3>
                <span className="border-left border-secondary text-center text-secondary"><small className="p-2">
                    Busque el alimento y agregue el pictograma con el botón +.</small>
                </span>
            </div>
            <div className="col-4">
                <h3>Paso 2</h3>
                <span className="border-left border-secondary text-center text-secondary"><small className="p-2">
                    Cambie a ingrediente, haga la búsqueda del alimento y agregue el pictograma con el botón +.
                                </small></span>
            </div>
            <div className="col-4">
                <h3>Paso 3</h3>
                <span className="border-left border-secondary text-center text-secondary"><small className="p-2">
                    Haga click en el botón "Listo" y su plato será agregado.
                            </small></span>
            </div>
        </div>
        )
}

export default Instrucciones;