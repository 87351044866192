import React, { createContext, useState, useContext } from 'react';

const PageHeightContext = createContext();

export const PageHeightProvider = ({ children }) => {
    const [pageHeights, setPageHeights] = useState({});
    const [totalPageHeights, setTotalPageHeights] = useState({});

    const setPageHeight = (pageId, sectionId, height) => {
        setPageHeights(currentPageHeights => {
            const updatedPageHeights = { ...currentPageHeights };
            if (!updatedPageHeights[pageId]) {
                updatedPageHeights[pageId] = {};
            }
            updatedPageHeights[pageId][sectionId] = height;
            const totalHeight = Object.values(updatedPageHeights[pageId]).reduce((sum, sectionHeight) => sum + sectionHeight, 0);
            setTotalPageHeights(currentTotalHeights => ({
                ...currentTotalHeights,
                [pageId]: totalHeight
            }));
            return updatedPageHeights;
        });
    };

    return (
        <PageHeightContext.Provider value={{ pageHeights,totalPageHeights,setPageHeight }}>
            {children}
        </PageHeightContext.Provider>
    );
};

export const usePageHeightContext = () => useContext(PageHeightContext);
