import Swal from 'sweetalert2';

export const alertAdd = (title) => {
    Swal.fire({
        position: 'center',
        icon: 'success',
        title: title,
        showConfirmButton: false,
        timer: 1500 
    });
}

