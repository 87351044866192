import React, { Fragment } from 'react';


const MsjMenuVacio = () => {

    return (
        <Fragment>
                <div className="col-12 text-center text-white my-4 py-4">
                <h1>No tienes ningún menú creado</h1>
                <h3>Agregá una página y comenzá a crearlo</h3>
                </div>
        </Fragment>   
    )
   
}

export default MsjMenuVacio;