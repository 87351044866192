import React, { Component } from 'react';
import './stepTwo.css';
import logo from '../Resources/2do.svg';
import puertaAbierta from '../Resources/puerta-abierta.png';
import puedoCaminar from '../Resources/puedo-caminar.png';
import luzTenue from '../Resources/luz-tenue.png';
import sonidoBajo from '../Resources/sonido-bajo.png';
import grupo from '../Resources/grupo.png'
import grupoDos from '../Resources/grupo-dos.jpg'
import Card from '../Card/Card';
import Pasos from '../Pasos/Pasos';
import ScrollToTop from '../ScrollTop/ScrollTop';

export class StepTwo extends Component {
    constructor(props) {
        super(props);
    }
    steps = [
        {number: 1, url: '/pasouno'},
        {number: 2, url: '/pasodos'},
        {number: 3, url: '/pasotres'},
        {number: 4, url: '/pasocuatro'},
        {number: 5, url: '/gracias'}
    ];

    render() {
        return (
            <main class="container-fluid text-center container-pasos">
                <ScrollToTop/>
                <Pasos>
                    <Card svg={logo} title={"Adaptaciones sensoriales"} width={"100"} height={"100"} maxWidth="1400" animation={false} pagination={true} current={this.steps[1]} pasos={this.steps}>
                        <div class="card-body-funciones step-two-body">
                            <div className='row d-flex align-items-center justify-content-center'>
                               <div className='col-md-12'>
                                    <p>Consiste en crear y/o adaptar la experiencia/entorno, a través de la reducción de estímulos sensoriales, de manera que las funciones puedan ser disfrutadas por todas las personas.</p>
                                    <br />
                                    <p>Las adaptaciones sensoriales necesarias son:</p>
                                    <br />
                                </div>
                            </div>
                            <div className='row d-flex align-items-start justify-content-center text-left'>
                               <div className='col-md-12'>
                                    <ul>
                                        <li>Elegir, en caso de ser posible, salas de fácil acceso y que no requieran atravesar espacios con muchos estímulos sensoriales (patio de comidas, tiendas, etc.)</li><br />  
                                        <li>Elegir horarios con menor flujo de gente para realizar las funciones.</li><br/>
                                        <li>Mantener las puertas abiertas de las salas para que las personas puedan circular libremente. </li><br />
                                    </ul>
                                    <div className='row text-center'>
                                        <div className='col-md-12'>
                                            <img src={puertaAbierta} width={150} alt="" />
                                            <img src={puedoCaminar} width={150} alt="" />
                                        </div>
                                    </div>
                                    <ul>
                                        <li>Ocupar entre un 60% y 75% de la capacidad de la sala, dependiendo las localidades (para evitar congestión en entradas y salidas; y favorecer la libre circulación dentro de la sala)</li><br />
                                        <li>Evitar el uso de aromatizantes en salas previo a la función.</li><br/>
                                        <li>Modificar la iluminación, dejar luces tenues encendidas.  </li><br/>
                                    </ul>
                                    <div className='row text-center'>
                                        <div className='col-md-12'>
                                            <img src={luzTenue} width={150} alt="" />
                                        </div>
                                    </div>
                                    <ul>
                                        <li>En obras de teatro: evitar luces estroboscópicas, estridentes, flashes, linternas   etc. y, de ser posible, evitar también los globos, burbujas, papel picado, etc.</li><br />
                                        <li>Modificar el sonido, reducir el volumen evitando que superen los 65db.</li><br />
                                    </ul>
                                    <div className='row text-center'>
                                        <div className='col-md-12'>
                                            <img src={sonidoBajo} width={150} alt="" />
                                        </div>
                                    </div>
                                    <ul>
                                        <li>Eliminar o reducir el volumen de la música en las zonas comunes o de espera previas al ingreso a la sala.</li><br />
                                        <li>Quitar trailers y publicidades previas a la película y/o intervalos durante la función.</li><br />
                                        <li>En las obras de teatro, invitar al público a aplaudir a través del lenguaje de señas para evitar que las personas con hipersensibilidad auditiva puedan desregularse.</li><br />
                                        <li>Ofrecer a los espectadores auriculares atenuantes de sonidos o permitir que ingresen a la sala con los suyos propios.</li><br />
                                        <li>Recomendamos permitir, a quienes asisten a la función, poder llevar pertenencias que los ayuden a regularse (siempre y cuando no sean con sonido o luminosos).</li><br />
                                        <li>Permitir, de ser posible, el ingreso a la sala con su propia comida y bebida ya que muchas personas la utilizan para regularse y a veces no consumen lo que se ofrece en los kioscos de los establecimientos.</li><br />
                                        <li>Es importante que haya una temperatura aproximada de 24 grados ya que las temperaturas altas o bajas pueden generar desregulaciones sensoriales y conductuales.</li><br />
                                        <li>Recomendamos trabajar colaborativamente con vendedores ambulantes para evitar la sobrecarga de estímulos y el acercamiento excesivo a los consumidores.</li><br />
                                        <li>Contar con un área de regulación:  este espacio tiene como objetivo, brindar a las personas que necesiten regularse tanto sensorial como conductualmente un lugar tranquilo y adecuado para lograrlo.</li><br />
                                    </ul>
                                    <div className='row text-center'>
                                        <div className='col-md-12'>
                                            <img src={grupoDos} alt="" className='img-card m-1'/>
                                            <img src={grupo} alt="" className='img-card m-1'/>
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-start justify-content-center text-center mt-4">
                                        <div className='col-md-12'>
                                            <p>Muchas personas neurodivergentes suelen desregularse ante la exposición sostenida de estímulos sensoriales.</p>
                                            <p>Sugerimos:
                                                <ul className='text-left'>
                                                    <li>Que este espacio tenga piso blando, espacio seguro para sentarse y sea lo más despejado posible de estímulos visuales.</li><br />
                                                    <li>Que este espacio no cuente con objetos que compitan en atención con la función y que generen un problema a la hora de tener que devolverlos.</li><br />
                                                    <li>Limitar el espacio físico del área para dar claridad visual y espacial a las personas que lo utilicen (podría ser con banners, o algún material que no sea peligroso).</li><br />
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-md-6'>
                                    <ul>
                                        <li>Eliminar o reducir el volumen de la música en las zonas comunes o de espera previas al ingreso a la sala.</li>
                                        <li>Quitar trailers y publicidades previas a la película y/o intervalos durante la función.</li>
                                        <li>En las obras de teatro, invitar al público a aplaudir a través del lenguaje de señas para evitar que las personas con hipersensibilidad auditiva puedan desregularse.</li>
                                        <li>Ofrecer a los espectadores auriculares atenuantes de sonidos o permitir que ingresen a la sala con los suyos propios.</li>
                                        <li>Recomendamos permitir, a quienes asisten a la función, poder llevar pertenencias que los ayuden a regularse (siempre y cuando no sean con sonido o luminosos).</li>
                                        <li>Permitir, de ser posible, el ingreso a la sala con su propia comida y bebida ya que muchas personas la utilizan para regularse y a veces no consumen lo que se ofrece en los kioscos de los establecimientos.</li>
                                        <li>Es importante que haya una temperatura aproximada de 24 grados ya que las temperaturas altas o bajas pueden generar desregulaciones sensoriales y conductuales.</li>
                                        <li>Recomendamos trabajar colaborativamente con vendedores ambulantes para evitar la sobrecarga de estímulos y el acercamiento excesivo a los consumidores.</li>
                                        <li>Contar con un área de regulación:  este espacio tiene como objetivo, brindar a las personas que necesiten regularse tanto sensorial como conductualmente un lugar tranquilo y adecuado para lograrlo.</li>
                                    </ul>
                                </div> */}
                            </div>
                            {/* <div className='row d-flex align-items-start justify-content-center text-center'>
                                <div className="col-md-6">
                                    <img src={grupoDos} alt="" className='img-card'/>
                                </div>
                                <div className="col-md-6">
                                    <img src={grupo} alt="" className='img-card'/>
                                </div>
                            </div>
                            <div className="row d-flex align-items-start justify-content-center text-center mt-4">
                                <div className='col-md-6'>
                                    <p>Muchas personas neurodivergentes suelen desregularse ante la exposición sostenida de estímulos sensoriales.</p>
                                    <p>Sugerimos:
                                        <li>Que este espacio tenga piso blando, espacio seguro para sentarse y sea lo más despejado posible de estímulos visuales.</li>
                                        <li>Que este espacio no cuente con objetos que compitan en atención con la función y que generen un problema a la hora de tener que devolverlos.</li>
                                        <li>Limitar el espacio físico del área para dar claridad visual y espacial a las personas que lo utilicen (podría ser con banners, o algún material que no sea peligroso).</li>
                                    </p>

                                </div>
                            </div> */}
                        </div>                        
                    </Card>
                </Pasos>
            </main>
            

        );
    }
}
