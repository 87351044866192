import React, { useState, Fragment, useContext } from "react";
import { Modal } from 'react-bootstrap';
import BusquedaPic from "../BusquedaPic/BusquedaPic";
import Plato from "../Plato/Plato";
import NuevoIngrediente from "../NuevoIngrediente/NuevoIngrediente";
import BtnEliminar from "../BtnEliminar/BtnEliminar";
import { alertAdd } from '../../Alerts';
import { MenuContext } from "../Context/menuContext";
import '../../styles/buttons.css';
import $ from 'jquery';
import uid from "uid";
import Instrucciones from "../Instrucciones/Instrucciones";
import ImportImage from "../ImportImage/ImportImage";
import Swal from "sweetalert2";
const maxCharNombre = 50;
const maxCharPalabra = 14;
const maxCharPrecio = 8;
const estilosPlato = {
    backgroundColor: "white",
    borderRadius: "10%",
    paddingTop:"15px"
};

const estiloInputPicto = {
    width: "80%",
    height: "100px",
    textAlign: "center",
    resize: "none"
}

const ModalEditarProducto = ({ data, monedaActual,isEditModalOpen,setIsModalOpen }) => {

    const { updateProducto } = useContext(MenuContext);
    const [plato, setPlato] = useState(data);
    const handleClose = () => setIsModalOpen(false);
    const [isPlatoTab, setIsPlatoTab] = useState(true);

    const estadoIngrediente = {
        idIngrediente: uid(),
        idProducto: plato.idProducto,
        nombre: '',
        src: ''
    };
    const [ingrediente, setIngrediente] = useState(estadoIngrediente);

    const [srcImg, setSrcImg] = useState("");

    const [pictograms, setPictograms] = useState([]);

    const [parametroBusqueda, setParametroBusqueda] = useState("");

    const onChange = e => {
        setPlato({ ...plato, [e.target.name]: e.target.value });
    };

    const resetInputs = ()=>{
        setParametroBusqueda("")
        setPictograms([])
        setIngrediente("")
        cleanIngrediente()
        $("#formBuscar")[0].reset();
        handleClose();
    }

    const onSubmit = () => {
        if ((plato.srcPic === "" && plato.srcImg === "") || plato.nombre.trim() === "") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "Para guardar el plato en tu men\u00FA, por favor asignale un nombre y adjunt\u00E1 un pictograma y/o foto",
                showConfirmButton: true,
            })
            return
        }
        if (plato.precio.length > maxCharPrecio) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "El precio no puede tener m\u00E1s de " + maxCharPrecio + " caracteres",
                showConfirmButton: true,
            })
            return
        }
        if (plato.nombre.length <= maxCharNombre) {
            let palabras = plato.nombre.split(' ')
            for (let i = 0; i < palabras.length; i++) {
                if (palabras[i].length > maxCharPalabra) {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "Las palabras ingresadas no deben exceder los " + maxCharPalabra + " caracteres",
                        showConfirmButton: true,
                    })
                    return
                }
            }
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "El nombre del ingrediente no debe exceder los " + maxCharNombre + " caracteres",
                showConfirmButton: true,
            })
            return
        }
        updateProducto(plato);
        resetInputs()
        alertAdd("Producto actualizado"); 
    };

    const onCancel = () => {
       setPlato(data);
       resetInputs()
    }

   

    const addPicto = (urlPicto) => {
        if (isPlatoTab) {
            addPictoProducto(urlPicto);
        }
        else {
            addPictoIngrediente(urlPicto)
        }
    }

    const addImg = (urlImg) => {
        setPlato({ ...plato, srcImg: urlImg });
    }

    const addPictoProducto = (urlPicto) => {
        setPlato({ ...plato, srcPic: urlPicto });
    }


    const addPictoIngrediente = (urlPicto) => {
        setIngrediente({ ...ingrediente, src: urlPicto });
    }
 
    const addIngrediente = (ingrediente) => {
        setPlato({ ...plato, ingredientes: [...plato.ingredientes, ingrediente] });
    };

    const cleanIngrediente = () => {
        setIngrediente(estadoIngrediente);
    }

    const cleanProductPic = () => {
        setPlato({ ...plato, srcPic: "" });
    }
    const cleanProductImg = () => {
        setPlato({ ...plato, srcImg: "" });
    }

    const deleteIngrediente = (idIngrediente) => {
        const ingredientesResult = plato.ingredientes.filter(ingrediente => ingrediente.idIngrediente != idIngrediente);
        setPlato({ ...plato, ingredientes: ingredientesResult });
    }
    return (
        <Fragment>
            <Modal show={isEditModalOpen} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                <Modal.Header className="mx-auto">
                    <Instrucciones />
                </Modal.Header>
                <Modal.Body>
                    <div id="plato" style={estilosPlato}>
                        <div className="row">
                            <div id="BusquedaPics" className="col-4 p-0 ">
                             {isPlatoTab?   <ImportImage addImg={addImg} srcImg={srcImg} setSrcImg={setSrcImg} />:null}
                                <BusquedaPic addpicto={addPicto} pictograms={pictograms} setPictograms={setPictograms} parametroBusqueda={parametroBusqueda} setParametroBusqueda={setParametroBusqueda} />
                            </div>
                            <div className="col-8">
                                <div className="row d-flex justify-content-center">
                                <h3 className="text-dark mb-4">{isPlatoTab?"Plato":"Ingrediente"}</h3>
                                <div className="d-flex">
                                    <button className="btn btn-light text-secondary mr-2" onClick={()=> setIsPlatoTab(true)}>Plato</button>
                                    <button className="btn btn-light text-secondary" onClick={()=> setIsPlatoTab(false)} >Ingrediente</button>
                                    </div>
                                    {isPlatoTab ? <div className="col-12 p-2">
                                        <form onSubmit={onSubmit} className="text-center">
                                            <div className="row justify-content-center">
                                                <div className="col-sm-12 col-md-8">
                                                    <div className="form-group">
                                                        {plato.srcPic === "" ? <textarea
                                                            type="text"
                                                            name="srcPic"
                                                            className="form-control"
                                                            placeholder="(Pictograma del plato)"
                                                            onChange={onChange}
                                                            style={estiloInputPicto}
                                                        /> : <div className="text-center">
                                                            <BtnEliminar click={() => cleanProductPic()} />
                                                            <img
                                                                src={plato.srcPic}
                                                                alt="Img plato"
                                                                width="156"
                                                                height="156"
                                                            />
                                                        </div>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-8">
                                                    <div className="form-group">
                                                        {plato.srcImg === "" ? <textarea
                                                            type="text"
                                                            name="src"
                                                            className="form-control"
                                                            placeholder="(Foto del plato)"
                                                            onChange={onChange}
                                                            style={estiloInputPicto}
                                                        /> : <div className="text-center">
                                                            <BtnEliminar click={() => cleanProductImg()} />
                                                            <img
                                                                src={plato.srcImg}
                                                                alt="Img plato"
                                                                width="156"
                                                                height="156"
                                                            />
                                                        </div>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-8">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="nombre"
                                                            maxLength={maxCharNombre}
                                                            className="form-control text-dark"
                                                            placeholder="Nombre"
                                                            onChange={onChange}
                                                            value={plato.nombre}
                                                            style={{ textAlign: "center" }}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="precio"
                                                            className="form-control text-dark"
                                                            placeholder="$0.00"
                                                            maxLength={maxCharPrecio}
                                                            onChange={onChange}
                                                            value={plato.precio}
                                                            style={{ textAlign: "center" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div> : <div className="col-12 p-2">
                                        <NuevoIngrediente
                                            plato={plato}
                                            estadoIngrediente={estadoIngrediente}
                                            addIngrediente={addIngrediente}
                                            setIngrediente={setIngrediente}
                                            ingrediente={ingrediente}
                                            canAddAnother={plato.ingredientes.length < 8}
                                            cleanIngrediente={cleanIngrediente}
                                        />
                                    </div>}
                                    <div className="col-12">
                                        <hr />
                                        <h3 className="text-center font-weight-bold">Vista previa</h3>
                                        <Plato isModal={true} producto={plato} btnEliminar={true} btnEliminarIngrediente={true} deleteIngrediente={deleteIngrediente} monedaActual={monedaActual} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer pr-4 justify-content-center">
                    <button
                        className="primary-line-button mt-2"
                        type="button"
                        onClick={onCancel}
                    >
                        Cancelar
                    </button>

                    <button type="button"
                        className="primary-button  mt-2" onClick={onSubmit}
                    >
                        Listo!
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default ModalEditarProducto;