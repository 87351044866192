import React, { Component } from "react";
import $ from 'jquery';
import Arma from '../Resources/arma.svg';
import PaginadorComercios from '../PaginadorComercios/PaginadorComercios'
import '../PaginadorComercios/paginadorComercios.css';
//NO MODIFICAR ID'S DE TIPOS DE COMERCIO NI NOMBRE DE LOS TIPOS DE COMERCIO QUE YA EXISTEN, SOLO SE PUEDE AGREGAR NUEVOS TIPOS DE COMERCIO.
//SE PUEDE AGREGAR SIGUIENDO EL ORDEN ASCENDENTE DESDE EL ID DEL TIPO DE COMERCIO ANTERIOR AL TIPO DE COMERCIO CON ID 1000 HASTA EL 1000
const tiposComercio = [
    { id: 1, nombre: "Cafeter\u00EDa" },
    { id: 2, nombre: "Kiosco" },
    { id: 3, nombre: "Panader\u00EDa" },
    { id: 4, nombre: "Parrilla" },
    { id: 5, nombre: "Restaurante" },
    { id: 6, nombre: "Helader\u00EDa" },
    { id: 1000, nombre: "Otros" }
];
const initState = {
    nombre: "",
    tipo: "0",
    provincia: "0",
    ciudad: "0",
    direccion: "",
    comercios: [],
    busco: false,
    pagina: "",
    limite: "",
};
let comercios;
let listaProvincias;
let listaTodasCiudades;
let listaCiudadesProvinciaSeleccionada;
async function getProvincias() {
    return fetch(`https://apis.datos.gob.ar/georef/api/provincias?aplanar=true&campos=estandar&max=5000&formato=json`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
    }).then(response => response.json()).then((data) => {
        $("#valProvinciaCiudad").hide();
        return data;
    }).catch(err => {
        const errProvinciaCiudad = "Hubo un problema al obtener los datos de las provincias y ciudades";
        initState.errProvinciaCiudad = errProvinciaCiudad;
        $("#valProvinciaCiudad").show();
    });
}
const listTempPro = await getProvincias();
listaProvincias = listTempPro.provincias.sort(function (a, b) {
    if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
    if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
    return 0;
});
async function getCiudades() {
    return fetch(`https://apis.datos.gob.ar/georef/api/departamentos?aplanar=true&campos=estandar&max=5000&formato=json`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
    }).then(response => response.json()).then((data) => {
        $("#valProvinciaCiudad").hide();
        return data;
    }).catch(err => {
        const errProvinciaCiudad = "Hubo un problema al obtener los datos de las provincias y ciudades";
        initState.errProvinciaCiudad = errProvinciaCiudad;
        $("#valProvinciaCiudad").show();
    });
}
const listaTempCiu = await getCiudades();
listaTodasCiudades = listaTempCiu.departamentos.sort(function (a, b) {
    if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
    if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
    return 0;
});

function setOptionsCiudades(idProvincia) {
    const depProvSel = listaTodasCiudades.filter(x => {
        const values = Object.values(x);
        if (values[4] == idProvincia) {
            return x;
        }
    });
    listaCiudadesProvinciaSeleccionada = depProvSel;
    $("option[class='optRemoveCiudad']").remove();
    for (let i = 0; i < depProvSel.length; i++) {
        $('#ciudad').append(`<option class = "optRemoveCiudad" key = ${depProvSel[i].id} value = "${depProvSel[i].id}" >${depProvSel[i].nombre}</option>`);
    }
}

async function getComercios() {
    const nombre = $('#nombre').val();
    const tipo = $('#tipo').val() == null ? "" : $('#tipo').val();
    const provincia = $("#provincia option:selected").text() == "-Provincia-" ? "" : $("#provincia option:selected").val();
    const ciudad = $("#ciudad option:selected").text() == "-Ciudad-" ? "" : $("#ciudad option:selected").val();
    const direccion = $('#direccion').val();
    return fetch(`/api/comercio/GetComercioFiltrado?nombre=${nombre}&tipo=${tipo}&provincia=${provincia}&ciudad=${ciudad}&direccion=${direccion}&activo=${true}`, {
        method: "GET",
    }).then(response => response.json()).then((data) => {
        return data;
    }).catch(err => {
    });
}

async function getCantComercios() {
    return fetch(`/api/comercio/GetCantComerciosActivos`, {
        method: "GET",
    }).then(response => response.json()).then((data) => {
        return data;
    }).catch(err => {
    });
}
const cantComerciosTotal = await getCantComercios();

export class ComerciosAdheridos extends Component {
    static displayName = ComerciosAdheridos.name;

    constructor(props) {
        super(props);
        this.state = initState;
        this.buscar = this.buscar.bind(this);
        this.clearFiltros = this.clearFiltros.bind(this);
    };

    componentDidMount() {
        this.setState({ busco: false });
        $("#cargandoComercios").hide();
    }

    handleChange = e => {
        const { target: { name, value } } = e;
        this.setState({ [name]: value });
        if (e.target.name == "provincia") {
            setOptionsCiudades(e.target.value);
        }
    }

    handleKeyPress = event => {
        if (event.key === 'Enter') {
            this.buscar();
        }
    };

    async buscar() {
        $(".comercioMostrado").hide();
        comercios = await getComercios();
        for (let i = 0; i < comercios?.length; i++) {
            for (let j = 0; j < listaProvincias?.length; j++) {
                if (comercios[i].provincia !== "") {
                    if (listaProvincias[j].id == comercios[i].provincia) {
                        comercios[i].provincia = listaProvincias[j].nombre;
                        if (comercios[i].ciudad != "") {
                            for (let k = 0; k < listaTodasCiudades?.length; k++) {
                                if (listaTodasCiudades[k].id == comercios[i].ciudad) {
                                    comercios[i].ciudad = listaTodasCiudades[k].nombre;
                                }
                            }
                        }
                    }
                }
            }
            for (let l = 0; l < tiposComercio?.length; l++) {
                if (tiposComercio[l].id == comercios[i].tipo) {
                    comercios[i].tipo = tiposComercio[l].nombre;
                }
            }
        }
        $(".comercioMostrado").show();
        this.setState({ busco: true });
        this.setState({ comercios: comercios });
    }

    clearFiltros() {
        $("#nombre").val("");
        $("#tipo").val("0");
        $("#provincia").val("0");
        $("#ciudad").val("0");
        $("#direccion").val("");
        this.setState(() => initState)
    }

    render() {
        var claseHayComercios = (cantComerciosTotal === 0 || (cantComerciosTotal > 0 && this.state.comercios?.length > 0 && this.state.busco === true)
            || (cantComerciosTotal > 0 && this.state.comercios?.length === 0 && this.state.busco === true)) ?
            "col-md-5 d-flex justify-content-md-end justify-content-center align-self-start"
            : (cantComerciosTotal > 0 && this.state.comercios?.length === 0 && this.state.busco === false) ?
                "d-flex justify-content-center"
                : "col-md-5 d-flex justify-content-md-end justify-content-center align-self-start";
        return (
            <main class="container-fluid text-center mb-5">
                <div class="row mt-5">
                    <div class="col-md-12">
                        <h3 class="title">Comercios adheridos</h3>
                    </div>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class={"col-10 " + claseHayComercios} >
                        <div className="col-md-9 mb-5 partners cardFiltros" style={{ backgroundColor: 'white' }}>
                            <div className="form">
                                <form action="" method="post" name="registroDeComercio" id="registroDeComercio">
                                    <div className="row justify-content-between">
                                        <h3 className="mb-4 text-left col-9">¿Qué tipo de comercio estás buscando?</h3>
                                        <button
                                            onClick={this.clearFiltros}
                                            title="Limpiar filtros"
                                            type="button"
                                            className={"text-right col-2 mr-2 mb-4 botonEliminarFiltros"}
                                            data-toggle="tooltip"
                                            data-html2canvas-ignore>
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                    <div className="form-group w-100 mt-2">
                                        <input type="text" name="nombre" className="form-control" id="nombre" placeholder="Nombre del comercio" value={this.state.nombre ? this.state.nombre : null} onChange={this.handleChange} onKeyUp={this.handleKeyUp} onKeyPress={this.handleKeyPress} />
                                    </div>
                                    <div className="form-group w-100 mt-2">
                                        <select name="tipo" className="custom-select" placeholder="Tipo de comercio" id="tipo" value={this.state.tipo ? this.state.tipo : null} onChange={this.handleChange} onClick={this.handleKeyUp} >
                                            <option value="0" selected defaultValue>-¿Qué buscas?-</option>
                                            {tiposComercio.map(({ id, nombre }) => <option value={id}>{nombre}</option>)}
                                        </select>
                                    </div>
                                    <div className="form-group w-100 mt-2">
                                        <select name="provincia" className="custom-select" placeholder="Seleccione una Provincia" id="provincia" value={this.state?.provincia ? this.state.provincia : null} onChange={this.handleChange} onKeyPress={this.handleKeyPress}>
                                            <option defaultValue>-Provincia-</option>
                                            {listaProvincias?.map((provincia) => <option key={provincia.nombre} value={provincia.id}>{provincia.nombre}</option>)}
                                        </select>
                                    </div>
                                    <div className="form-group w-100 mt-2">
                                        <select name="ciudad" className="custom-select" placeholder="Seleccione una Ciudad" id="ciudad" value={this.state?.ciudad ? this.state.ciudad : null} onChange={this.handleChange} onKeyPress={this.handleKeyPress}>
                                            <option defaultValue>-Ciudad-</option>
                                            {setOptionsCiudades(this.state.provincia)}
                                        </select>
                                    </div>
                                    <div className="form-group w-100 mt-2">
                                        <input type="text" name="direccion" className="form-control" placeholder="Direcci&oacute;n" id="direccion" value={this.state.direccion} onChange={this.handleChange} onKeyUp={this.handleKeyUp} onKeyPress={this.handleKeyPress} />
                                    </div>
                                    <div className="form-group w-100">
                                        <button type="button"
                                            data-dismiss="modal"
                                            className="primary-button-small mt-2" onClick={this.buscar}
                                            id="buttonBuscar">
                                            Buscar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="col-10 col-md-7">
                        <div class="ml-md-5 mb-3 animate__fadeInLeft animate__animated partners w-75" id="cargandoComercios" style={{ backgroundColor: 'white' }}>
                            <h3>Cargando los comercios...</h3>
                        </div>
                        {cantComerciosTotal > 0 ?
                            this.state.comercios?.length > 0 && this.state.busco === true ?
                                <PaginadorComercios comercios={this.state.comercios} pagina={1} limite={6} />
                                : this.state.comercios?.length === 0 && this.state.busco === true ?
                                    <div class="ml-md-5 mb-3 animate__fadeInLeft animate__animated partners w-75" style={{ backgroundColor: 'white' }}>
                                        <img src={Arma} width="10%" alt="Busqueda sin resultados"></img>
                                        <h3>Tu consulta no ha arrojado resultados, probá con menos filtros.</h3>
                                    </div>
                                    : null
                            : <div class="ml-md-5 mb-3 animate__fadeInLeft animate__animated partners w-75" style={{ backgroundColor: 'white' }}>
                                <img src={Arma} width="10%" alt="Busqueda sin resultados"></img>
                                <h3>Por el momento no hay comercios adheridos.<br></br>Probá volviendo en unos días.</h3>
                            </div>}
                    </div>
                </div>
            </main>
        );
    }
}