import React, { Component } from 'react';
import '../Registro/Registro.css';
export class RestablecerPassword extends Component {
    static displayName = RestablecerPassword.name;

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            confirmPass: "",
            sec_quest: 0,
            answ: "",
            errPassword: "",
            errConfirmPass: "",
            errInfo: "",
        };
    };

    handleChange = e => {
        const { target: { name, value } } = e;
        this.setState({ [name]: value });

    };

    handleValidation = () => {
        const { email, password, confirmPass } = this.state;
        let errEmail, errPassword, errConfirmPass = "";
        if (email.length < 6 || !(email.includes("@"))) {
            errEmail = "El email es incorrecto.";
        }
        if (password.length < 6) {
            errPassword = "La contraseña es demasiado corta.";
        }
        if (!(password === confirmPass)) {
            errConfirmPass = "Las contraseñas no son iguales.";
        }
        if (errEmail || errPassword || errConfirmPass) {
            this.setState({ errEmail, errPassword, errConfirmPass });
            return false;
        }
        return true;
    };

    handleSubmit = e => {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ errInfo: "", errEmail: "", errPassword: "", errConfirmPass: "" });
            fetch("/recover", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify({
                    email: this.state.email,
                    contrasena: this.state.password,
                    preguntaSeguridad: parseInt(this.state.sec_quest),
                    respuestaSeguridad: this.state.answ.toLowerCase().replace(/ /g, "")
                })
            }).then((res) => {
                if (res.status === 200) {
                    alert('Contraseña restablecida con éxito.');
                    this.props.history.push('/ingreso');
                }
                else {
                    this.setState({ errInfo: "Información incorrecta." });
                }
            });
        }
    }
    render() {
        return (
            <div id="card" className="container shadow-lg p-3 mb-5 rounded vh-100">
                <h1 className="text-center font-weight-bold" style={{ color: "#064EB4" }}>Reestablecer <br></br>contraseña</h1>
                <div className="row">
                    <div className="col-md-9 mx-auto">
                        <div className="form">
                            <form action="" method="post" name="forgotpassword">
                                <div className="form-group">
                                    <input type="email" name="email" className="form-control input text-white" id="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} />
                                    <span className="d-block text-center text-danger mt-1">{this.state.errEmail}</span>
                                </div>
                                <div className="form-group">
                                    <select type="sec_quest" className="custom-select" name="sec_quest" id="sec_quest" onChange={this.handleChange} style={{ border: "1px solid #064EB4" }}>
                                        <option disabled selected defaultValue>-Seleccione su pregunta de seguridad-</option>
                                        <option value={1} >Nombre de tu primera mascota</option>
                                        <option value={2} >Personaje histórico favorito</option>
                                        <option value={3} >Lugar de nacimiento de tu madre</option>
                                        <option value={4} >Personaje de ficción favorito</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <input type="answ" name="answ" className="form-control input" id="answ" placeholder="Respuesta" value={this.state.answ} onChange={this.handleChange} />
                                </div>
                                <div className="form-group">
                                    <input type="password" name="password" className="form-control input" id="password" placeholder="Nueva contraseña" value={this.state.password} onChange={this.handleChange} />
                                    <span className="d-block text-center text-danger mt-1">{this.state.errPassword}</span>
                                </div>
                                <div className="form-group">
                                    <input type="password" name="confirmPass" className="form-control input" id="confirmPass" placeholder="Confirmar contraseña" value={this.state.confirmPass} onChange={this.handleChange} />
                                    <span className="d-block text-center text-danger mt-1">{this.state.errConfirmPass}</span>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary btn-block" onClick={this.handleSubmit}>Enviar respuesta</button>
                                    <span className="d-block text-center text-danger mt-1">{this.state.errInfo}</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
