import React, { createContext, useState, useEffect, Fragment, useContext} from 'react';
import MenuServices from '../../MenuServices/MenuServices';
import userLoginContext from '../userContext';
import uid from 'uid';
import Cookie from 'js-cookie';

export const MenuContext = createContext({
    menu: null,
    paginas: [],
    secciones: [],
    productos: []
});

export default ({ children }) => {
    const [menu, setMenu] = useState(null);
    const [paginas, setPaginas] = useState([]);
    const [secciones, setSecciones] = useState([]);
    const [productos, setProductos] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const { userAuth } = useContext(userLoginContext);
    
    const userId = Cookie.get("UserId");

    useEffect(() => {
        if (userAuth) {
            MenuServices.getMenu(userId).then(
                menuData => {
                    setMenu({
                        idMenu: menuData.idMenu,
                        idUsuario: menuData.idUsuario,
                        nombre: menuData.nombre,
                        paginas: menuData.paginas
                    });
                },
                e => {
                    setMenu({
                        idMenu: uid(),
                        idUsuario: userId,
                        nombre: '',
                        paginas: []
                    });
                }
            );
        }
    }, []);

    useEffect(() => {
        if (!menu) {
            return;
        }
        const paginasList = [];
        const seccionesList = [];
        const productosList = [];
        menu.paginas.forEach(pagina => {
            paginasList.push({
                idPagina: pagina.idPagina,
                idMenu: menu.idMenu
            });
            pagina.secciones.forEach(seccion => {
                seccionesList.push({
                    idSeccion: seccion.idSeccion,
                    idPagina: pagina.idPagina,
                    nombre: seccion.nombre,
                });
                seccion.productos.forEach(producto => {
                    productosList.push({
                        idProducto: producto.idProducto,
                        idSeccion: seccion.idSeccion,
                        idPagina: pagina.idPagina,
                        srcPic: producto.srcPic,
                        srcImg: producto.srcImg,
                        nombre: producto.nombre,
                        precio: producto.precio,
                        ingredientes: producto.ingredientes
                    });
                })
            })
        });
        setPaginas(paginasList);
        setSecciones(seccionesList);
        setProductos(productosList);
        setIsLoaded(true);
    }, [menu]);

    const addPagina = (pagina) => {
        setPaginas([...paginas, pagina]);
    }

    const removePagina = (idPagina) => {
        setPaginas(paginas.filter(pagina => pagina.idPagina != idPagina));
        setSecciones(secciones.filter(seccion => seccion.idPagina != idPagina));
        setProductos(productos.filter(producto => producto.idPagina != idPagina));
    }

    const addSeccion = (seccion) => {
        setSecciones([...secciones, seccion]);
    }

    const removeSeccion = (idSeccion) => {
        setSecciones(secciones.filter(seccion => seccion.idSeccion != idSeccion));
        setProductos(productos.filter(producto => producto.idSeccion != idSeccion));
    }

    const updateNombreSeccion = (idSeccion, nombre) => {
        const updatedSecciones = secciones.map((seccion) => {
            if (seccion.idSeccion == idSeccion) {
                seccion.nombre = nombre;
            }
            return seccion;
        });
        setSecciones(updatedSecciones);
    }

    const addProducto = (producto) => {
        setProductos([...productos, producto]);
    }

    const removeProducto = (idProducto) => {
        setProductos(productos.filter(producto => producto.idProducto != idProducto));
    }

    const updateProducto = (productoActualizado) => {
        const updatedProduct = productos.map((producto) => {
            if (producto.idProducto == productoActualizado.idProducto) {
                producto.nombre = productoActualizado.nombre;
                producto.precio = productoActualizado.precio;
                producto.srcPic = productoActualizado.srcPic;
                producto.srcImg = productoActualizado.srcImg;
                producto.ingredientes = productoActualizado.ingredientes;
            }
            return producto;
        });
        setProductos(updatedProduct);
    }

    const addIngrediente = (ingrediente) => {
        const producto = productos.find(p => p.idProducto == ingrediente.idProducto);
        producto.ingredientes = [...producto.ingredientes, ingrediente];
    }

    const removeIngrediente = (ingrediente) => {
        const producto = productos.find(p => p.idProducto == ingrediente.idProducto);
        producto.ingredientes = producto.ingredientes.filter(i => i.idIngrediente == ingrediente.idIngrediente);
    }

    const getMenuObj = () => {
        const menuObj = {
            idMenu: menu.idMenu,
            idUsuario: menu.idUsuario,
            nombre: menu.nombre,
            paginas: []
        };

        paginas.forEach(pagina => {
            const paginaObj = {
                idPagina: pagina.idPagina,
                idMenu: menuObj.idMenu,
                secciones: []
            };

            const paginaSecciones = secciones.filter(seccion => seccion.idPagina == pagina.idPagina);

            paginaSecciones.forEach(paginaSeccion => {
                const seccionProductos = productos.filter(producto => producto.idSeccion == paginaSeccion.idSeccion);

                const seccionObj = {
                    idSeccion: paginaSeccion.idSeccion,
                    idPagina: paginaObj.idPagina,
                    nombre: paginaSeccion.nombre,
                    productos: seccionProductos.map(producto => {
                        return {
                            idProducto: producto.idProducto,
                            idSeccion: producto.idSeccion,
                            nombre: producto.nombre,
                            srcPic: producto.srcPic,
                            srcImg: producto.srcImg,
                            precio: producto.precio,
                            ingredientes: producto.ingredientes.map(ingrediente => {
                                return {
                                    idIngrediente: ingrediente.idIngrediente,
                                    idProducto: producto.idProducto,
                                    src: ingrediente.src,
                                    nombre: ingrediente.nombre
                                }
                            })
                        }
                    })
                };
                paginaObj.secciones.push(seccionObj);
            });
            menuObj.paginas.push(paginaObj);
        });

        return menuObj;
    }

    const getNewMenu = () => {
        MenuServices.getMenu(userId).then(menuData => {
            setMenu({
                idMenu: menuData.idMenu,
                idUsuario: menuData.idUsuario,
                nombre: menuData.nombre,
                paginas: menuData.paginas
            });
        }).catch(error => {
            console.log("Error al actualizar el menú");
        });
    };

    const context = {
        menu,
        getNewMenu,
        getMenuObj,
        paginas,
        addPagina,
        removePagina,
        secciones,
        addSeccion,
        removeSeccion,
        updateNombreSeccion,
        productos,
        addProducto,
        removeProducto,
        updateProducto,
        addIngrediente,
        removeIngrediente,

    };

    return (<Fragment> {!isLoaded & !userAuth? <h3> Cargando... </h3> :
        <MenuContext.Provider value={context}>
            {children}
        </MenuContext.Provider>
    }
    </Fragment>)
}
