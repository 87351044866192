import React from 'react';

const InstruccionesEditaTuComercio = () => {
    return (
        <div className="row justify-content-left">
            <div className="col-12">
                <h3>Tu comercio ya est&aacute; registrado</h3>
                <span className="border-left border-secondary text-center text-secondary"><small className="p-2">
                    Podés modificar los datos de tu comercio y luego hacer clic en Listo! para guardar los cambios.
                </small>
                </span>
            </div>
        </div>
    )
}

export default InstruccionesEditaTuComercio;