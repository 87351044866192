import React, { Fragment } from 'react';

const BtnAgregar= ({ click }) => {
    return (<Fragment>
        <button
            type="button"
            onClick={click}
            className="btn btn-primary w-100"
            data-toggle="tooltip"
            data-placement="top"
            data-html2canvas-ignore>
            <i className="fas fa-plus"></i>
        </button>
    </Fragment>)
}
export default BtnAgregar;