import React, { useState, useEffect } from 'react';
import './paginadorComercios.css';
const estiloLink = {
    display: "flex",
    flex: 1,
    marginRight: "0.5em",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
}
const estilosFoto = {
    backgroundColor: "white",
    borderRadius: "20%",
};
const PaginadorComercios = (props) => {
    const [comerciosPaginados, setComerciosPaginados] = useState([]);
    const [pagina, setpagina] = useState(props.pagina);
    const totalPaginas = Math.ceil(props.comercios?.length / props.limite);
    useEffect(() => {
        const comTemp = props.comercios.sort(function (a, b) {
            if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
            if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
            return 0;
        });
        setComerciosPaginados(comTemp);
        setpagina(props.pagina);
        paginar(props.pagina);
    }, [props.comercios])

    function paginar(pag) {
        let arrCom = [];
        if (props.comercios.length > 0) {
            arrCom = [];
            if (props.comercios.length < props.limite) {
                for (let i = 0; i < props.comercios.length; i++) {
                    if (i < props.comercios.length) {
                        arrCom.push(props.comercios[i]);
                    }
                }
                setpagina(1);
            } else {
                for (let i = ((pag - 1) * props.limite); i < (pag * props.limite); i++) {
                    if (i < props.comercios.length) {
                        arrCom.push(props.comercios[i]);
                    }
                }
                setpagina(pag);
            }
            setComerciosPaginados(arrCom.sort(function (a, b) {
                if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
                if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
                return 0;
            }));
        }
    }

    return (
        <div>
            <div className="row d-flex justify-content-md-start justify-content-center">
                {comerciosPaginados?.length > 0 ?
                    comerciosPaginados.map(({ idComercio, nombre, tipo, provincia, ciudad, direccion, webRedSocial, foto }) =>
                        <div key={idComercio ?? 0} className="row col-12 col-md-5 mr-md-4 mb-3 animate__fadeInLeft animate__animated cardComercio comercioMostrado" style={{ backgroundColor: 'white' }}>
                            <div className="col-12 col-lg-4 my-lg-auto mx-auto">
                                {foto !== "" ?
                                    <img className="estilosFoto" width="120" height="120" id="aaaaaaa" src={foto} alt="Cargando..." style={{ visibility: foto === "" ? 'hidden' : 'visible' }} />
                                    :
                                    <img
                                        src="https://placehold.co/156x156?text=Sin Logo"
                                        alt="Sin logo comercio"
                                        width="120"
                                        height="120"
                                        style={estilosFoto}
                                    />}
                            </div>
                            <div className="col-12 col-lg-8 my-lg-auto">
                                <h3 className="mx-0 px-0 mt-2 text-center"><b>{nombre}</b></h3>
                            </div>
                            <div className="col-12 mb-3 mt-3 text-left">
                                <div>
                                    <b class="text-secondary">{tipo}</b>
                                </div>
                                <div>
                                    <i className="bi bi-geo-alt mr-2"><b>{direccion}</b></i>
                                </div>
                                <div>
                                    {(provincia && ciudad) ?
                                        < div > <b>{provincia} - {ciudad}</b></div>
                                        : (provincia && !ciudad) ?
                                            < div > <b>{provincia}</b></div> : null
                                    }
                                </div>
                                <div style={estiloLink}>
                                    <i class="bi bi-globe2 mr-2" ></i>
                                    {(webRedSocial !== "Sin web o redes sociales") ?
                                        <a className="w-100" href={"http://" + webRedSocial} target="_blank" rel="noopener noreferrer" ><b>{webRedSocial}</b></a>
                                        : <b>{webRedSocial}</b>}
                                </div>
                            </div>
                        </div>
                    )
                    : null
                }
            </div>
            <div>
                <nav >
                    <ul className="pagination pagination-md justify-content-md-start justify-content-center">
                        {!(pagina - 1 < 1) ?
                            <li className="page-item" onClick={() => paginar(1)}><span className="page-link" style={{ color: '#064eb4' }}>&laquo;</span></li>
                            : null}
                        {!(pagina - 1 < 1) ?
                            <li className="page-item" onClick={() => paginar(pagina - 1)}><span className="page-link" style={{ color: '#064eb4' }}>&lsaquo;</span></li>
                            : null}
                        {!(pagina - 3 < 1) ?
                            <li className="page-item" onClick={() => paginar(pagina - 3)}><span className="page-link" style={{ color: '#064eb4' }}>...</span></li>
                            : null}
                        {!(pagina - 2 < 1) ?
                            <li className="page-item" onClick={() => paginar(pagina - 2)}><span className="page-link" style={{ color: '#064eb4' }}>{pagina - 2}</span></li>
                            : null}
                        {!(pagina - 1 < 1) ?
                            <li className="page-item" onClick={() => paginar(pagina - 1)}><span className="page-link" style={{ color: '#064eb4' }}>{pagina - 1}</span></li>
                            : null}
                        <li className="page-item active"><span className="page-link">{pagina}</span></li>
                        {!(pagina + 1 > totalPaginas) ?
                            <li className="page-item" onClick={() => paginar(pagina + 1)}><span className="page-link" style={{ color: '#064eb4' }}>{pagina + 1}</span></li>
                            : null}
                        {!(pagina + 2 > totalPaginas) ?
                            <li className="page-item" onClick={() => paginar(pagina + 2)}><span className="page-link" style={{ color: '#064eb4' }}>{pagina + 2}</span></li>
                            : null}
                        {!(pagina + 3 > totalPaginas) ?
                            <li className="page-item" onClick={() => paginar(pagina + 3)}><span className="page-link" style={{ color: '#064eb4' }}>...</span></li>
                            : null}
                        {!(pagina + 1 > totalPaginas) ?
                            <li className="page-item" onClick={() => paginar(pagina + 1)}><span className="page-link" style={{ color: '#064eb4' }}>&rsaquo;</span></li>
                            : null}
                        {!(pagina + 1 > totalPaginas) ?
                            <li className="page-item" onClick={() => paginar(totalPaginas)}><span className="page-link" style={{ color: '#064eb4' }}>&raquo;</span></li>
                            : null}
                    </ul>
                </nav >
            </div>
        </div>
    );
}

export default PaginadorComercios;