import React from "react";
import BtnAgregar from "../BtnAgregar/BtnAgregar";
const estilosFoto = {
    backgroundColor: "white",
    borderRadius: "20%",
};
const Image = (props) => {
    return (
        <div id="divImagen" className="border-bottom border-secondary" style={{ visibility: props.srcImg === "" ? 'hidden' :  'visible' }}>
            <img width="150" height="150" id="aaaaaaa" src={props.srcImg} alt="Cargando..." style={estilosFoto} />
            <BtnAgregar click={() => props.addImg(props.srcImg)}></BtnAgregar>
        </div>
    );
};
export default Image;