import React, { Component } from 'react';
import './footer.css';
import shiftaLogo from '../Resources/logo-shifta.png';

const Footer = (props) => {
    return (
        <>
            <footer class="footer">
                {props.showLeyend ?
                <p class="mt-5" style={{fontSize: "0.625rem"}}>
                Los símbolos pictográficos utilizados son propiedad del Gobierno de Aragón y han sido creados por Sergio Palao para ARASAAC (http://www.arasaac.org), que los distribuye bajo Licencia Creative Commons BY-NC-SA.
                </p> : "" }               

                <div class="p-2 d-flex justify-content-end align-items-end">
                    <div style={{width:"100"}}>Powered by<a href="https://www.weareshifters.com/" target="_blank" rel="noopener noreferrer"><img src={shiftaLogo} alt="SHIFTA" width="187" /></a>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;
