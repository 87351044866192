import Cookie from 'js-cookie';

export default {
        getMenu : id => {
        const token = Cookie.get("SSID");

        return fetch(`/api/menu/GetMenusByIdUsuario`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                },                
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw "Respuesta incorrecta del servidor"
                }
            });
        }
}



