import React from 'react';

const InstruccionesRegistraTuComercio = () => {
    return (
        <div className="row justify-content-left">
            <div className="col-12">
                <h3>Registrá tu comercio</h3>
                <span className="border-left border-secondary text-center text-secondary"><small className="p-2">
                    Ingresá los datos de tu comercio para que podamos cargarlos.<br></br>
                </small>
                </span>
            </div>
        </div>
    )
}

export default InstruccionesRegistraTuComercio;