import Image from './Image'
import Resizer from "react-image-file-resizer";
import $ from 'jquery';
import uid from "uid";
const ImportImage = ({ addImg, srcImg, setSrcImg }) => {

    let inputId = `InputFile-${uid()}}`
    async function manageImage(e) {
        $('#errorImg').text("");
        const errExtension = "La extensi\u00F3n de la imagen debe ser .jpg o .png"
        const errTamano = "La imagen no debe superar los 1,5 Mb."
        const errGeneral = "Hubo un error al intentar cargar la imagen."
        let pathImg = ""
        e.target.files[0] ? pathImg = e.target.files[0].name : pathImg = ""
        let ext = pathImg.slice(pathImg.lastIndexOf('.') + 1).toLowerCase();
        if (ext === "") {
            $('#errorImg').text(errExtension)
            setSrcImg("")
            return
        }
        if (!(ext === "jpg" || ext === "png" || ext === "jpeg")) {
            $('#errorImg').text(errExtension)
            setSrcImg("")
            return
        }
        if (e.target.files[0].size >= 1572864) {
            $('#errorImg').text(errTamano)
            setSrcImg("")
            return
        }
        try {
            await Resizer.imageFileResizer(
                e.target.files[0],
                156,
                156,
                e.target.files[0].type,
                0,
                0,
                (uri) => {
                    setSrcImg(uri)
                    $('#errorImg').text("")
                },
                "base64",
            );
        } catch (err) {
            $('#errorImg').text(errGeneral)
            setSrcImg("")
        }
    }
    return (
        <div id="ImportImage" className='text-center p-1'>
            <label for={inputId} style={{ cursor: "pointer" }}><i class="bi bi-paperclip"></i>Adjuntar una foto</label>
            <input id={inputId} type="file" accept="image/png, image/jpeg"
                style={{ display: "none", cursor: "pointer" }}
                onChange={(e) => manageImage(e)} 
                onClick={(e) => e.target.value = ''} />
            <span id="errorImg" className="d-block text-danger mt-1 text-align-left"></span>
            <div>
                <div id="imageImport">
                    <Image key={uid()} addImg={addImg} srcImg={srcImg} />
                </div>
            </div>
        </div>
    )
}

export default ImportImage