import React from 'react';
import { Link } from 'react-router-dom';
import fotoUno from '../Resources/foto-1.png';
import fotoDos from '../Resources/foto-2.png';
import fotoTres from '../Resources/foto-3.png';
import fotoCuatro from '../Resources/foto-4.png';
import fotoCinco from '../Resources/foto-5.png';
import fotoSeis from '../Resources/foto-6.png';
import pictogramas from './pictogramas.zip';

import './senializacionSectores.css';
const SenializacionSectores = (props) => {

    let downloadFile = () => {
        const element = document.createElement("a");
        element.href = pictogramas;
        element.download = "Pictogramas.zip";
        element.click();
    }

    return (
        <>
            <div className='row d-flex align-items-center justify-content-center mt-4'>
                <div className='col-md-12'>
                    <p>Todos los sectores del establecimiento deberían estar señalizados con pictogramas adaptados. Por ejemplo:  ascensores, escaleras, sala de cine/teatro, área de regulación, baños, kiosco, cafetería, estacionamiento, cajas, boleterías, salidas de emergencia, entre otros.</p>
                </div>
            </div>
            <div className='row d-flex align-items-center justify-content-center mt-4'>
                <div className='col-md-4'>
                    <img src={fotoUno} alt="" className="card-img-1" />
                </div>
                <div className='col-md-4'>
                    <img src={fotoDos} alt="" className="card-img-1" />
                </div>
                <div className='col-md-4'>
                    <img src={fotoTres} alt="" className="card-img-1" />
                </div>
            </div>
            <div className='row d-flex align-items-center justify-content-center mt-4'>
                <div className='col-md-12'>
                    <p>Buscá colocar  los pictogramas a una altura y ubicación accesibles para niños, niñas, adolescentes, adultos y usuarios de silla de ruedas. Colocalos a una altura media de 1,20 cm (mínimo 90 cm y máximo 1,60 cm de alto).</p>
                    <img src={fotoCuatro} alt="" className='card-img-2 mt-4'/>
                </div>
            </div>
            <div className='row d-flex align-items-start justify-content-center mt-4'>
                <div className='col-md-6'>
                    <p>En los lugares que cuenten con señalizaciones existentes, recomendamos sumar también los pictogramas adaptados e imprimirlos en un tamaño entre 15x15cm. y 20x20cm. para que sean visibles desde distintas distancias y perspectivas.</p>
                </div>
                <div className='col-md-6'>
                    <p>Para indicar la ubicación de los distintos sectores, podrás acompañar los pictogramas con flechas indicativas de dirección.</p> 
                </div>
            </div>
            <div className='row d-flex align-items-start justify-content-center mt-4'>
                <div className="col-md-6">
                    <img src={fotoCinco} alt="" className='card-img-3'/>
                </div>
                <div className="col-md-6">
                    <img src={fotoSeis} alt="" className='card-img-3'/>
                </div>
            </div>
            <div className='row d-flex align-items-center justify-content-center mt-4'>
                <div className='col-md-12'>
                    <p>Recomendamos que los pictogramas permanezcan en el establecimiento aún cuando no haya programadas funciones distendidas.</p>
                    <button className='btn btn-primary' onClick={downloadFile}>Descargar pictogramas</button>
                </div>
            </div>
        </>
    )
}

export default SenializacionSectores;
