import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';
const Card = (props) => {


    const results = [];
    let next = "";
    let back = "";
    let showBack = false;
    let showNext = true;
    if (props.pagination) {
        props.pasos.forEach((paso, index) => {
            results.push(
                <Link key={paso.number} to={paso.url} className="align-self-center">
                    <div className={'point ' + (paso.number === props.current.number ? "indicator-active" : "")} ></div>
                </Link>,
            );
        });
        showBack = props.current.number !== 1;
        showNext = props.current.number !== props.pasos.slice(-1)[0].number;
        console.log(showNext);
        console.log(props.pasos.slice(-1));
        console.log(props.pasos.slice(-1).number);
        next = showNext ? props.pasos.filter(x => x.number === props.current.number + 1)[0].url : "";
        back = showBack ? props.pasos.filter(x => x.number === props.current.number - 1)[0].url : "";

    }



    return (
        <>
            <Link to={props.link}>
                <div className={"container-card d-flex justify-content-center aling-items-center " + (props.animation ? "animation" : "")} style={{ maxWidth: `${props.maxWidth}px` }}>
                    <div className='card-image'><img src={props.svg} width={props.width} height={props.height} alt="" /></div>
                    <div className='card-body d-flex flex-column justify-content-center aling-items-center'>
                        <div className='card-title font-weight-bold'>{props.title}</div>
                        {props.children}
                        {props.pagination &&
                            <div className={"card-pagination d-flex flex-column justify-content-center aling-items-center"}>
                                <div className='d-flex justify-content-center aling-items-center indicator'>
                                    {results}
                                </div>
                                <div className='d-flex justify-content-center aling-items-center align-self-center botones'>
                                    {showBack &&
                                        <Link to={back}>
                                            <button className={'btn btn-primary btn-card'}>Anterior</button>
                                        </Link>
                                    }
                                    {showNext ?
                                        <Link to={next}>
                                            <button className='btn btn-primary btn-card'>Siguiente</button>
                                        </Link>
                                        :
                                        <Link to={props.pasos[0].url}>
                                            <button className='btn btn-primary btn-card'>Inicio</button>
                                        </Link>
                                    }

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Link>
        </>
    )
}
export default Card;
