import React, { Component } from 'react';
import logo from '../Resources/1ro.svg';
import './stepOne.css';
import Card from '../Card/Card';
import Pasos from '../Pasos/Pasos';
import ScrollToTop from '../ScrollTop/ScrollTop';

export class StepOne extends Component {
    constructor(props) {
        super(props);
    }
    steps = [
        {number: 1, url: '/pasouno'},
        {number: 2, url: '/pasodos'},
        {number: 3, url: '/pasotres'},
        {number: 4, url: '/pasocuatro'},
        {number: 5, url: '/gracias'}
    ];

    render() {
        return (
            <div class="container-fluid text-center container-pasos">
                <ScrollToTop/>
                <Pasos>
                    <Card svg={logo} title={"Capacitación al personal"} width={"100"} height={"100"} maxWidth="700" animation={false} pagination={true} current={this.steps[0]} pasos={this.steps}>
                        <div class="card-body-funciones">
                            <p>Es fundamental que el personal sea capacitado por especialistas, para que conozcan en profundidad las herramientas de accesibilidad y el porqué de las adaptaciones sensoriales y cognitivas que serán implementadas en una función distendida para lograr así una adecuada atención al espectador.</p>
                            <p>Para consultas escribinos a: <br/><u>salidasinclusivas@gmail.com</u></p>
                        </div>                        
                    </Card>
                </Pasos>
            </div>
            

        );
    }
}
